<template>
  <div class="flex items-center gap-[24px] text-theme-primary">
    <IconLogo class="h-[45px] sm:h-[67px]"/>
    <h1 class="invisible w-1 sm:visible sm:w-auto font-semibold text-theme-primary" :class="{ 'sm:text-[25px] lg:text-[45px]': large, 'sm:text-[25px]': !large }">
      <span>{{ platform }}</span>
    </h1>
  </div>
</template>
<script setup lang="ts">
const platform = usePlatformSettings().platformName;
defineProps<{ large?: Boolean }>()
</script>
